import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class Accounting extends React.Component {
	render() {
		const siteTitle = 'Accounting'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Accounting</h3>
								<p>
									<strong>Financial Accounting</strong>
									<br />
									WSC Group assists business clients with the preparation of
									financial statements. We help our clients to:
									<ul>
										<li>
											Comply with the requirements of the preparation of income
											tax returns
										</li>
										<li>
											Track financial performance indicators and forecasts
										</li>
										<li>
											Satisfy the client’s banks/financial institutions’
											information requirements
										</li>
									</ul>
								</p>
								<p>
									{' '}
									<strong>Management Accounting</strong>
									<br />
									WSC Group will advise business clients how to establish and
									maintain effective financial control by assisting with:
								</p>
								<h4>
									We provide a complete service including preparation of returns
									and advice in the following areas:
								</h4>
								<ul>
									<li>
										The creation of appropriate key indicators and monitoring
										systems
									</li>
									<li>Costing analysis</li>
									<li>Reviews of financial structure</li>
									<li>Restructuring and planning options</li>
								</ul>
								<p>
									{' '}
									<strong>Budgeting &amp; Cashflow</strong>
									<br />
									WSC Group budgeting and cashflow service helps both small and
									large businesses to establish and maintain cashflow and budget
									controls. WSC Group can either prepare the reports or help
									clients establish their own control systems.
								</p>
								<p>
									<strong>Bookkeeping</strong>
									<br />
									WSC Group will assist clients to understand the importance of
									bookkeeping and reduce the burden of bookkeeping by:
								</p>
								<p>
									<ul>
										<li>
											Establishing bookkeeping systems (from very simple through
											to the more complex) and training personnel in their use
										</li>
									</ul>
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Accounting

export const AccountingPageQuery = graphql`
	query AccountingPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
